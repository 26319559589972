@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Tulpen+One&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  /* font-family: "Work Sans", sans-serif !important; */
  font-family: "Tulpen One", cursive !important;
  scroll-behavior: smooth;
  scroll-padding-top: 7.875rem;
}

@media (max-width: 768px) {
  html {
    scroll-padding-top: 3.65rem;
  }
}

.react-multi-carousel-list {
  border-radius: 0.25rem;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}

.react-multi-carousel-dot-list {
  background-color: #efece8;
  gap: 1rem;
  opacity: 0.7;
}

.react-multi-carousel-dot--active button {
  background: #334a3b !important;
}

.react-multi-carousel-item {
  height: 100%;
}

.react-multi-carousel-item img {
  width: 100%;
  height: 22.5rem;
  object-fit: cover;
}

#home .react-multi-carousel-item img {
  object-fit: contain;
  height: 100%;
}

#prodotti .react-multi-carousel-item img {
  height: 20rem;
  object-fit: contain;
}

#prodotti .react-multiple-carousel__arrow {
  bottom: 2rem;
}

#prodotti .react-multi-carousel-dot-list {
  gap: 0.5rem;
}

#calendar {
  font-family: "Work Sans", sans-serif !important;
}

body {
  margin: 0;
  letter-spacing: 0.25px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.calendar {
  font-family: "Tulpen One", cursive !important;
  font-size: 1.5rem !important;
  height: 100%;
  overflow: hidden;
  border-radius: 0.5rem;
  color: #160f0f !important;
  max-width: 42rem;
  margin: auto;
}

.calendar .body .row .cell .number {
  font-size: 100% !important;
  top: 50% !important;
  left: 50%;
  right: unset !important;
  transform: translate(-50%, -50%);
}

.calendar .days {
  color: #160f0f !important;
}

.calendar .header {
  padding: 0.5rem !important;
}

.calendar .timeSelector {
  font-family: "Tulpen One", cursive !important;
}

.calendar .body .row .cell {
  height: 2.8em !important;
  transition: none !important;
}

.calendar .timeSelector .cell {
  height: auto !important;
}

.calendar .body .disabled {
  opacity: 0.75;
  pointer-events: none;
  cursor: not-allowed !important;
}

.disabled {
  cursor: not-allowed !important;
  pointer-events: none;
  cursor: not-allowed !important;
}

.Sun,
.Mon,
.t12-45,
.t13-00,
.t13-15,
.t13-30,
.t13-45,
.t20-15,
.t01-11,
.t08-12,
.t26-12,
.t02-01,
.t03-01,
.t04-01,
.t05-01,
.t06-01,
.t07-01,
.t25-04,
.t01-05,
.t02-06,
.t13-08,
.t14-08,
.t15-08,
.t16-08,
.t17-08,
.t18-08,
.t19-08,
.t20-08,
.t21-08,
.t01-01,
.t02-01,
.t03-01,
.t04-01,
.t05-01,
.t22-01,
.t06-01,
.t22-08,
.t23-08,
.t24-08,
.t25-08,
.t26-08,
.t27-08,
.t28-08,
.t26-10,
.t08-12,
.t25-12,
.t26-12,
.Sun p,
.Mon p,
.t12-45 p,
.t13-00 p,
.t08-12 p,
.t22-01 p,
.t01-01 p,
.t02-01 p,
.t03-01 p,
.t04-01 p,
.t05-01 p,
.t06-01 p,
.t25-12 p,
.t26-12 p,
.t13-15 p,
.t13-30 p,
.t13-45 p,
.t20-15 p,
.t01-11 p,
.t08-12 p,
.t26-12 p,
.t02-01 p,
.t03-01 p,
.t04-01 p,
.t05-01 p,
.t06-01 p,
.t07-01 p,
.t25-04 p,
.t01-05 p,
.t02-06 p,
.t13-08 p,
.t14-08 p,
.t15-08 p,
.t16-08 p,
.t17-08 p,
.t18-08 p,
.t19-08 p,
.t20-08 p,
.t21-08 p,
.t22-08 p,
.t23-08 p,
.t24-08 p,
.t25-08 p,
.t26-08 p,
.t27-08 p,
.t28-08 p,
.t26-10 p {
  cursor: not-allowed !important;
  color: #e52b50 !important;
  pointer-events: none;
}

.Mon.t23-12,
.Mon.t30-12 {
  cursor: pointer !important;
  color: black !important;
  pointer-events: all;
}

.t30-12.disabled,
.t30-12.disabled p {
  color: #ccc !important;
}

.timeSelector.t08-12 .body,
.timeSelector.t08-12 .body,
.timeSelector.t25-12 .body,
.timeSelector.t26-12 .body,
.timeSelector.t26-12 .body,
.timeSelector.t01-11 .body,
.timeSelector.t01-01 .body,
.timeSelector.t02-01 .body,
.timeSelector.t03-01 .body,
.timeSelector.t04-01 .body,
.timeSelector.t05-01 .body,
.timeSelector.t06-01 .body,
.timeSelector.t02-01 .body,
.timeSelector.t03-01 .body,
.timeSelector.t04-01 .body,
.timeSelector.t05-01 .body,
.timeSelector.t06-01 .body,
.timeSelector.t07-01 .body,
.timeSelector.t13-08 .tbody,
.timeSelector.t14-08 .tbody,
.timeSelector.t15-08 .tbody,
.timeSelector.t16-08 .tbody,
.timeSelector.t17-08 .tbody,
.timeSelector.t18-08 .tbody,
.timeSelector.t19-08 .tbody,
.timeSelector.t20-08 .tbody,
.timeSelector.t21-08 .tbody,
.timeSelector.t22-08 .tbody,
.timeSelector.t23-08 .tbody,
.timeSelector.t24-08 .tbody,
.timeSelector.t25-08 .tbody,
.timeSelector.t26-08 .tbody,
.timeSelector.t27-08 .tbody,
.timeSelector.t28-08 .tbody,
.timeSelector.t25-04 body,
.timeSelector.t01-05 body,
.timeSelector.t22-01 body,
.timeSelector.t02-06 body,
.timeSelector.t26-10 body {
  display: none;
}

.calendar .body + .timeSelector {
  display: none !important;
}

.calendar .timeSelector + .timeSelector {
  display: block !important;
}

.calendar .timeSelector .disabled {
  color: #e52b50 !important;
  pointer-events: none;
  cursor: not-allowed !important;
}

.calendar .body .row .selected {
  border: 0 !important;
  border-right: 1px solid #eee !important;
}

.calendar .body .selected .bg {
  opacity: 0 !important;
}

.calendar .body .cell .bg {
  font-size: 3rem !important;
  left: 0rem;
  top: 0 !important;
  right: unset !important;
}

/* map */
.leaflet-control-attribution {
  display: none;
}

.leaflet-layer,
.leaflet-control-zoom-in,
.leaflet-control-zoom-out,
.leaflet-control-attribution {
  filter: invert(100%) hue-rotate(180deg) brightness(95%) contrast(90%);
}
